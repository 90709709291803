.homepage,
.subpage {
  font-weight: 400;
  font-style: normal;
  font-family: 'Red Hat Text', sans-serif;

  main {
    background-color: $light-gray;

    section,
    nav {
      padding: 40px 120px;

      @media only screen and (max-width: 768px) {
        padding: 40px 60px;
      }
    }

    article {
      padding-top: 40px;
    }

    nav {
      display: flex;
      padding: 10px 120px;
      width: 100%;
      background-color: $dark-blue;
      color: white;

      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
        display: block;
        padding: 10px 60px;
      }

      .featured-content-1 {
        // float: right;
      }

      .featured-content-2 {
        padding-right: 30px;
        width: 50%;
        font-style: italic;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      a {
        color: white;
        text-decoration: none;
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
        font-family: 'Red Hat Text', sans-serif;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        &:before {
          position: relative;
          display: inline-block;
          padding-right: 10px;
          content: '\f0a8';
          font-weight: 700;
          font-family: 'Font Awesome 5 Free';
        }
      }
    }

    .page-title {
      padding: 60px 120px;
      background-color: $primary-blue;

      @media only screen and (max-width: 768px) {
        padding: 60px;
      }

      h2 {
        margin: 0;
        color: white;
        font-weight: 100;
        font-style: normal;
        font-size: 60px;
        font-family: 'Red Hat Text', sans-serif;

        @media only screen and (max-width: 414px) {
          font-size: 45px;
        }
      }
    }
  }
}