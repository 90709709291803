header.primary {
  position: relative;
  padding: 0 120px;
  width: 100%;
  background: white;

  @media only screen and (max-width: 768px) {
    padding: 0 60px;
  }

  .branding {
    display: flex;
    min-height: 272px;
    width: 100%;

    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .company,
    .client {
      width: 50%;

      @media only screen and (max-width: 768px) {
        width: auto;

        img {
          width: 100%;
        }

        .company-logo,
        .client-logo {
          float: none;
        }
      }
    }

    .company {
      .company-logo {
        float: left;
      }
    }

    .client {
      .client-logo {
        float: right;
        max-height: 220px;
      }
    }
  }
}
