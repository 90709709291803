/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Normalize
@import 'vendor/normalize';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/images';
@import 'components/iebar';
// Vendor
@import 'vendor/accessibility';