$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors
$primary-blue: #003e7e;
$dark-blue: #002d59;
$light-gray: #edf0f2;
$medium-gray: #e1e3e6;
$off-white: #f5f8fa;
$gray-blue: #3d444d;
// fonts
