footer.primary {
  position: relative;
  display: block;
  padding: 40px 120px;
  background-color: $gray-blue;
  color: white;

  .footer-content {
    display: inline-flex;
    width: 100%;

    align-items: center;

    .brand {
      position: relative;
      display: inline-flex;
      margin-right: 25px;
      max-width: 130px;
      width: 100%;

      &:after {
        position: absolute;
        right: -25px;
        width: 1px;
        height: 100%;
        background-color: #5b6168;
        content: '';
      }
    }

    .tagline {
      margin-left: 25px;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 16px;

      a {
        color: white;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
